import { useState, useEffect, useRef, React } from "react";
import SimpleImageSlider from "react-simple-image-slider"; 

function Home() {
    const images = [
        {url: '/assets/tacbanner.jpg'},
        {url: '/assets/10356173_1520059841583422_3020286823494852883_n.jpg'},
        {url: '/assets/10888665_1523631674559572_24244415797477485_n.jpg'},
        {url: '/assets/10476103_1499453480310725_2803270871255240040_o.jpg'},
        {url: '/assets/15665708_1812723648983705_6950348750300084775_n.jpg'},
        {url: '/assets/25592042_1977984445790957_3305453553901477904_n.jpg'},
        {url: '/assets/80065273_2476012112654852_8874197453457326080_n.jpg'}
      ];    

    const ref = useRef();

    const [state, setState] = useState ({
        width: window.innerWidth / 2,
        height: window.innerWidth / 3.5
    });

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, [state, ref])

    const handleResize = () => {
        setState(currentState => ({...currentState, width: window.innerWidth / 1.8, height: window.innerWidth / 3.3}));
    };

    return (
        <div className="container page-content">
            <div className="card page-content" ref={ref}>
                {(window.innerWidth > 500) && (<SimpleImageSlider
                    width={state.width}
                    height={state.height}
                    images={images}
                    showBullets={true}
                    showNavs={true}
                    className="carousel"
                />)}
                <span className="mobile-gallery">
                    <SimpleImageSlider
                        width={state.width * 1.5}
                        height={state.height * 1.5}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        className="carousel"
                    />
                </span>
                <p style={ {textAlign: "center"} }>A community project in Wolverton which aims to provide a fresh meal and entertainment to anyone who is alone on Christmas day</p>
            </div>
        </div>
    )
};

export default Home;