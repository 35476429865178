import React, { useState } from "react";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/e2277ca0-5ac1-11ed-b82c-5d75eaa7ccff"; //todo - fill in later

function Contact () {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = () => {
        setTimeout(() => {
        setSubmitted(true);
        }, 100);
    };

    if (submitted) {
        return (
        <div className="container">
            <div className="card">
                <h3>Thank you!</h3>
                <p>Your message has been successfully sent to the Together At Christmas team. We'll be in with you touch soon 😊</p>
            </div>
        </div>
        );
    };

    return (
        <div className="container">
            <div className="card">
                <form
                    action={FORM_ENDPOINT}
                    onSubmit={handleSubmit}
                    method="POST"
                    target="_blank"
                >
                    <label htmlFor="fname">First Name</label>
                    <input type="text" id="fname" name="firstname" placeholder="Your name..." />
                    <label htmlFor="lname">Surname</label>
                    <input type="text" id="sname" name="surname" placeholder="Your surname..." />
                    <label htmlFor="email">Email Address</label>
                    <input type="text" id="email" name="email" placeholder="Your email address..."/>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" placeholder="Write your message here..." style={{height:"10em"}}></textarea>
                    <input type="submit" value="Submit" className="btn red darken-1"/>
                </form>
            </div>
        </div>
    );
};

export default Contact;