import React from "react";
import {ReactComponent as Logo} from "../assets/taclogo.svg"

function Footer () {
    return (
        <div className="footer-copyright page-content">
            <Logo alt="logo" fill="red" className="brand-logo" />
            <a href="https://www.github.com/johnnyjoxenfree" className="footer" target="_blank">Made with ♥ by Isaac John</a>
        </div>
    )
};

export default Footer;