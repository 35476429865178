import './index.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";  
import Home from './components/Home.js';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contact from './components/Contact';
import About from './components/About';

function App() {
  return (
    <div className='main'>
        <Navbar />
      <div className='main-content'>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
