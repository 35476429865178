import { useState, useEffect, React } from "react";
import { ReactComponent as Logo } from "../assets/taclogo-base.svg";
import { Menu } from "react-feather";

function Navbar()  {
    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const toggleNavbar = () => {
        setToggleMenu(!toggleMenu);
    };

    useEffect(() => {

        const changeWidth = () => {
        setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }

    }, [])

    return (
        <div className="navbar-fixed">
            <nav className='nav-wrapper red darken-1'>
                <div className='container'>
                    {(toggleMenu || screenWidth > 500) && (
                    <div>
                        <a href="/" className="center-span brand-logo full-size">
                            <span><Logo alt="logo" className="logo" /></span>
                            <span>&nbsp;Together at Christmas</span>
                        </a>
                        <ul className="right list">
                            <li className="items"><a href="/about">About</a></li>
                            <li className="items"><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    )}
                <a href="/" className="center-span brand-logo left mobile">
                    <span><Logo alt="logo" className="logo" /></span>    
                </a>
                <ul><li className="right mobile" onClick={toggleNavbar}><a><Menu /></a></li></ul>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;