import React from "react";
import { Facebook, Instagram } from "react-feather";

function About () {
    return (
        <div className="container">
            <div className="card">
                <h3>About Us</h3>
                <p>Together at Christmas is a community project in Wolverton which aims to provide a fresh meal and entertainment to anyone who is alone on Christmas 
                    day. Hosting our first gathering in 2012, we have continued to serve Wolverton and the wider community on Christmas day by providing food, 
                    gifts, and company to anybody who wants or needs it.</p>
                <br />
                <p>The day is run by a group of volunteers, sourcing food, making hampers, and coordinating guests and recipients for Together at Christmas; our volunteers 
                are from a range of backgorunds - some new, and some who have been with us from the very beginning.
                </p><br /><p>Over the years, we have developed relationships with multiple local businesses and organisations who continue to support us every year, 
                    and without whom we would not be able to run. Organisations such as the Milton Keynes Christian Foundation, and more recently, Milton Keynes Community
                    Fridge, who we have partnered with for the last 4 years to not only get food for our guests, but to do so through supermarket rescue, thereby preventing
                    perfectly good food from ending up in landfill.
                </p>
                <br />
                <p>If you are a person or an organisation who would like to inquire about making a donation, please do so through our contact page, and we will reach out to you
                as soon as we can.</p>
                <div className="right-align">
                    <span> 
                        <a href="https://www.instagram.com/together_at_christmas_mk/" target="_blank"><Instagram /></a>
                    </span>
                    &nbsp;&nbsp;
                    <span>
                        <a href="https://www.facebook.com/togetheratchristmasmk/" target="_blank"><Facebook /></a>
                    </span>
                </div>
            </div>
        </div>
    )
};

export default About;